export default [
  {
    title: 'Alpa',
    route: { name: 'project-alpa' },
    icon: 'CloudIcon',
  },
  {
    title: 'Thor',
    route: { name: 'project-thor' },
    icon: 'CommandIcon',
  },
]
